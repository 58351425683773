<template>
  <div>
  </div>
</template>

<script>
import Auth from '@/apis/AuthApi'
export default {
  mounted() {
    this.sendConfirmation(this.$route.params.token)
  },
  methods: {
    sendConfirmation: function (token) {
      Auth.confirm(token)
        .then(async ({ data }) => {
            if (data.success) {
              this.showSuccessSystemNotification('Email успешно подтвержден')
            } else {
              this.showErrorSystemNotification('Произошла ошибка при подтверждении Email')
            }
        })
        .then(() => this.$router.push({ name: 'ChooseRole' }))
        .finally(() => this.$setLoading(false))
    }
  }
}
</script>
